import { Component } from "react";
import SpinnerLoader from "../components/loader";

class MainComponent extends Component {
  constructor() {
    super();
    this.texts = {
      placeholderText: `Paieškos žodis (anglų kalba)`,
      searchButtonText: `Ieškoti`,
    };
    this.buttonClick = this.buttonClick.bind(this);
    this.fetchLink = `https://gifai.artefaktas.workers.dev/api`;
    this.state = {
      data: null,
      loading: null,
    };
    this.cardStyle = {
      width: 80 + `%`,
    };
    this.cardimgStyle = {
      maxHeight: 30 + `rem`,
    };
  }

  buttonClick() {
    let fieldSelector = document.getElementById("searchInput");
    let fetchUrl = this.fetchLink;
    if (fieldSelector.value !== "") {
      this.setState({ loading: true });
      async function getData() {
        let fetchHeaders = {
          "q-word": String(fieldSelector.value),
        };
        let d = await fetch(fetchUrl, {
          headers: fetchHeaders,
        });
        let jsonData = await d.json();
        return jsonData;
      }
      getData().then((r) => {
        this.setState({
          data: r.data,
          loading: false,
        });
      });
    }
  }

  render() {
    return (
      <div>
        <input
          id="searchInput"
          className="form-control form-control-lg my-4"
          placeholder={this.texts.placeholderText}
        />
        <button
          className="btn form-control btn-dark btn-lg"
          onClick={this.buttonClick}
        >
          {this.texts.searchButtonText}
        </button>
        <div className="row">
          {this.state.loading === true ? (
            <div className="col-lg-12 col-md-12 col-sm-12 my-2 col-xs-12 mx-auto">
              <SpinnerLoader />
            </div>
          ) : this.state.data !== null ? (
            this.state.data.map((giph, i) => {
              return (
                <div
                  className="col-lg-6 col-md-8 col-sm-12 my-2 col-xs-12 mx-auto"
                  key={i}
                >
                  <div
                    className="card my-2 d-block mx-auto"
                    style={this.cardStyle}
                  >
                    <img
                      loading="lazy"
                      className="card-img-top"
                      src={giph.images.downsized_large.url}
                      alt={giph.slug}
                      style={this.cardimgStyle}
                    />

                    <div className="card-body">
                      <h5 className="card-title">{giph.title}</h5>
                      <p className="card-text">{giph.slug}</p>
                      <button
                        className="btn btn-dark form-control my-4"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#id` + i}
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Nuorodos
                      </button>
                      <div className="collapse" id={`id` + i}>
                        <p>
                          <textarea className="form-control" rows="10">
                            {giph.images.downsized.url}
                          </textarea>
                        </p>
                        <a
                          target="_blank"
                          href={giph.url}
                          className="btn btn-primary"
                          rel="noreferrer"
                        >
                          Giphy nuoroda
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default MainComponent;
